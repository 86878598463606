import React from 'react'
import { Route, BrowserRouter as Router, Routes, Outlet } from 'react-router-dom'
import AppNavBar from '../../components/AppBar/AppBar'

function Home(props) {

  return <div className='home-page'>
        <div>
          <AppNavBar />
          <div style={{margin: "65px 2px"}}>
            <Outlet />
          </div>
        </div>
        <div />
    </div>
}

export default Home