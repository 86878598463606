import React, {lazy} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as axiosInterceptor from './helpers/axios-interceptor.js'
import App from './App';
import { Provider } from 'react-redux'
import store from './app/store';
const delay = () => import('./styles.scss')
setTimeout(() => delay(), 0)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);