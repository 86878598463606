// https://github.com/LottieFiles/lottie-react
import { Player, Controls } from '@lottiefiles/react-lottie-player';


function LottiePlayer(props){
    const {animationData, style, autoplay, loop, speed} = props
    
    return <Player
        autoplay={autoplay}
        loop={loop}
        src={animationData}
        style={style}
        speed={speed}
    >
        {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
    </Player>
}

LottiePlayer.defaultProps={
    animationData : "https://assets3.lottiefiles.com/packages/lf20_UJNc2t.json",
    style : { height: '200px', width: '300px' },
    autoplay: true,
    loop: false,
    speed: 1
}


export default LottiePlayer