
const initialState = { selectedComponentsData: {}, selectedBrandFilter:{}, isLoading: false }


export default function shop(state = initialState, action) {
    switch (action.type) {
        case 'SET_SELECTED_COMPONENTS_DATA':
            return { ...state, selectedComponentsData: {...state.selectedComponentsData, [action.payload.id]: action.payload} };
        case 'SET_ALL_SELECTED_COMPONENTS_DATA':
            return { ...state, selectedComponentsData: {...action.payload }};
        case 'DELETE_COMPONENT_DATA_BY_ID':
            let data = JSON.parse(JSON.stringify(state.selectedComponentsData))
            delete data[action.payload]
            return { ...state, selectedComponentsData: {...data }};
        case 'DELETE_ALL_SELECTED_COMPONENTS_DATA':
            return { ...state, selectedComponentsData: { }};
        default:
            return state
    }
}