import React, { lazy, Suspense } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import Home from './layouts/Home/Home.js'
import ReactGA from "react-ga4";

import * as animation from './assets/lottiefiles/loading.json'
import LottiePlayer from './components/LottiePlayer/index.js';
// const Home = lazy(() =>  import('./layouts/Home/Home.js'));
const DashboardLayout = lazy(() =>  import('./layouts/Dashboard/DashboardLayout'));
const PrepareDocument = lazy(() =>  import('./layouts/DocFlow/PrepareDocument/PrepareDocument'));
const AddSigners = lazy(() =>  import('./layouts/DocFlow/PrepareDocument/AddSigners'));
const ReviewSendEmail = lazy(() =>  import('./layouts/DocFlow/PrepareDocument/ReviewSendEmail.js'));
const SelectFlowType = lazy(() =>  import('./layouts/DocFlow/PrepareDocument/SelectFlowType'));
const UploadDocTemplate = lazy(() =>  import('./layouts/DocFlow/PrepareDocument/UploadDocTemplate'));
const SignPageLayout = lazy(() =>  import('./layouts/DocFlow/SignPageLayout/SignPageLayout'));
const SignedDocumentLayout = lazy(() =>  import('./layouts/DocFlow/SignedDocument/SignedDocumentLayout'));
const TemplateLayout = lazy(() =>  import('./layouts/DocFlow/TemplateLayout/TemplateLayout'));
const TemplatesLayout = lazy(() =>  import('./layouts/Templates/TemplatesLayout'));
const OpenedDocumentLayout = lazy(() =>  import('./layouts/DocFlow/OpenedDocument/OpenedDocumentLayout'));
const LoginLayout = lazy(() =>  import('./layouts/Login/LoginLayout.js'));
const RegisterLayout = lazy(() =>  import('./layouts/Login/RegisterLayout.js'));
const LandingPage = lazy(() =>  import('./layouts/CustomerLanding/LandingPage.js'));


function App() {
  try {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
  } catch (error) {
    console.log("Error initializing google analytics");
  }

  return (
    <div className="app-root">
      <Router>
      <Suspense fallback={<p>Loading... Waiting for the satellites to align...</p>}>
        <Routes>
          <Route path='/login' element={<LoginLayout />} />
          <Route path='/register' element={<RegisterLayout />} />
          <Route path='/wow' element={<LandingPage />} />
          <Route path='/about' element={<LandingPage />} />
          <Route path='/' element={<Home />} >
            <Route path='/template/:id' element={<TemplateLayout />} />
            <Route path='/templates' element={<TemplatesLayout />} />
            <Route path='/signed/:id' element={<SignedDocumentLayout />} />
            <Route path='/signed' element={<SignedDocumentLayout />} />
            <Route path='/sign/:id' element={<SignPageLayout />} />
            <Route path='/sign' element={<SignPageLayout />} />
            <Route path='/document/:id' element={<OpenedDocumentLayout />} />
            <Route path='/prepare' element={<PrepareDocument />} />
            <Route path='/selectflow' element={<SelectFlowType />} />
            <Route path='/upload' element={<UploadDocTemplate />} />
            <Route path='/addsigners' element={<AddSigners />} />
            <Route path='/review' element={<ReviewSendEmail />} />
            <Route path='/dashboard' element={<DashboardLayout />} />
            <Route path='/' element={<SelectFlowType />} />
          </Route>
        </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default React.memo(App);
