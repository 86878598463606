import { combineReducers } from 'redux'
import docmaker from './docmaker'
import docflow from './docflow'
import settings from './settings'
import templates from './templates'
import documents from './documents'
export default combineReducers({
    docmaker,
    docflow,
    settings,
    templates,
    documents
})