const initialState = {
  preparationFlowData: null,
  signerData: null,
  emailBodyData: null,
  documentCreationRequestData: {},
};

export default function docflow(state = initialState, action) {
  switch (action.type) {
    case "SET_DOCUMENT_UPLOAD_DATA":
      return { ...state, selectedFileUploadData: action.payload };
    case "SET_PREPARTION_FLOW":
      // sign-yourself || send-to-others || create-template || use-template
      return { ...state, preparationFlowData: action.payload };
    case "SET_DOCUMENT_CREATION_REQUEST_DATA":
      return { ...state, documentCreationRequestData: action.payload };
    case "SET_SIGNERS_DATA":
      return { ...state, signerData: action.payload };
    case "SET_EMAIL_BODY_DATA":
      return { ...state, emailBodyData: action.payload };
    default:
      return state;
  }
}
