const initialState = {
    documentList:[],
};

export default function templates(state = initialState, action) {
  switch (action.type) {
    case "SET_DOCUMENT_LIST_DATA":
      return { ...state, documentList: [...action.payload] };
    case "SET_DOCUMENT_DATA":
      return { ...state, documentData: { ...action.payload } };
    default:
      return state;
  }
}
