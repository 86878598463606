const initialState = {
    templatesList:[],
};

export default function templates(state = initialState, action) {
  switch (action.type) {
    case "SET_TEMPLATES_DATA":
      return { ...state, templatesList: [...action.payload] };
    case "SET_TEMPLATE_DATA":
      return { ...state, templateData: { ...action.payload } };
    case "CLEAR_TEMPLATE_DATA":
      return { ...state, templateData: null };
    case "SET_USE_TEMPLATE_DATA":
      return { ...state, useTemplateData: { ...action.payload } };
    case "CLEAR_USE_TEMPLATE_DATA":
      return { ...state, useTemplateData: null };
    default:
      return state;
  }
}
