const initialState = { documentPenStyle: "hand-written-text", isLoading: false, };

export default function shop(state = initialState, action) {
  switch (action.type) {
    case "SET_DOC_PEN_STYLE":
      return { ...state, documentPenStyle: { ...action.payload } };
    default:
      return state;
  }
}
