import axios from "axios";
// axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (request) => {
    // ... modify request;
    // appending http,host,port to all requests
    request.url =
      process.env.REACT_APP_API_PROTOCOL +
      process.env.REACT_APP_API_HOST +
      ":" +
      process.env.REACT_APP_API_PORT +
      process.env.REACT_APP_API_PREFIX +
      request.url;

      // appending x-auth-token for all requests
    let xAuthToken = localStorage.getItem("xAuthToken");
    request.headers["x-auth-token"] = xAuthToken;
    console.log(request)

    // Passing withCredentials as true for passport to send data as part of cookies
    request.withCredentials =true;

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response)=>{
    if(response.config.url.includes('/auth/login')){
      // when on login api call, get the x-auth-token from response and store in local storage
      let xAuthToken = response.headers['x-auth-token']
      localStorage.setItem('xAuthToken', xAuthToken);
    }
    return response
  },
  (error)=>{
     // Any status codes that falls outside the range of 2xx cause this function to trigger
     const { response } = error;
     if (response && response.status === 401 && response.data.code === 'UNAUTHORIZED_ACCESS') {
         // Handle logout or redirect to login page
         window.location.href = '/login';
     }
    return Promise.reject(error);
  }
);


